import React, { FC, useMemo } from "react";
import { AccountInterface } from "../../interfaces";
import { useAppSelector } from "../../store/hooks";

interface AccountBillToCardProps {
  account: AccountInterface;
}

const AccountBillToCard: FC<AccountBillToCardProps> = ({ account }) => {
  const taxIdentificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );

  const indentification = useMemo(() => {
    const type = taxIdentificationTypes.find(
      (t) => t.taxIdentificationTypeId === account.taxIdentificationTypeID
    );

    return (type?.abreviationName ?? "") + account.identificationNumber;
  }, [account, taxIdentificationTypes]);

  const phoneNumber = useMemo(() => {
    return account.listAccountPhone[0]?.phoneNumber;
  }, [account]);

  const email = useMemo(() => {
    return account.listAccountEmail[0]?.email;
  }, [account]);

  return (
    <div className="flex flex-row gap-4">
      <div className="flex flex-col text-sm text-gray-800">
        <p>FACTURA A NOMBRE DE:</p>
        <p>Identificación:</p>
        {phoneNumber && <p>Teléfono:</p>}
        {email && (<p>Correo:</p>)}
        {account.fiscalAddress && (<p>Dirección:</p>)}
      </div>

      <div className="flex flex-col text-sm text-gray-800">
        <p className="text-gray-800 font-medium">{account.accountFullName}</p>
        <p>{indentification}</p>
        {phoneNumber && (<p>{phoneNumber}</p>)}
        {email && (<p>{email}</p>)}
        {account.fiscalAddress && (<p>{account.fiscalAddress}</p>)}
      </div>
    </div>
  );
};

export default AccountBillToCard;
