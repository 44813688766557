import PriceCalculator from "../modules/PriceCalculator/PriceCalculator";
import ShipmentPay from "../modules/ShipmentPay/ShipmentPay";
import Document from "../modules/Document";
import ShipmentView from "../modules/ShipmentView";

interface AppRoute {
  index?: boolean;
  path?: string;
  element: React.ReactNode;
  /**
   * If the route needs authentication
   */
  auth?: boolean;
  exact?: boolean;
  rolesRequired?: string[];
}

const AppRoutes: AppRoute[] = [
  {
    path: "/shipment/:shipmentNumber",
    element: <ShipmentView />,
    auth: false,
  },
  {
    path: "/shipment/:shipmentID/pay",
    element: <ShipmentPay />,
    auth: false,
  },
  {
    path: "/document/:documentID",
    element: <Document />,
    auth: false,
  },
  { path: "/price-calculator", element: <PriceCalculator />, auth: false },
  // { path: "/shipment-create", element: <ShipmentCreate />, auth: true },
];

export default AppRoutes;
