import { useEffect, useMemo, useState } from "react";
import logo from "../assets/LogoTEALCA.svg";
import LoadingIcon from "../components/LoadingIcon";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { getDocument, getAccount, loaderService, userLogin, CreateOrUpdateSession } from "../services";
import {
  DocumentInterface,
  DocumentStatus,
  DocumentType,
  PaymentStatusEnum,
  UserInterface,
} from "../interfaces";
import classNames from "classnames";
import { LinkText, PrimaryButton } from "../components/Buttons";
import { ClientDetails } from "../components/Shipment/ShipmentHeader";
import { documentStatusFormat } from "../utils";
import ShipmentTable from "../components/Shipment/ShipmentTable";
import PaymentTotal from "../components/Payment/PaymentTotal";
import { ChevronLeftIcon, CurrencyDollarIcon } from "@heroicons/react/24/outline";
import ShipmentPayments from "./ShipmentPay/ShipmentPayments";
import { setUser } from "../store/slices";

const Document = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { documentID } = useParams();
  const user = useAppSelector((state) => state.user);

  const url = new URL(window.location.href);
  const hideLogo = url.searchParams.get("hide-logo");

  const [loading, setLoading] = useState(true);
  const [document, setDocument] = useState<DocumentInterface>();
  const [documentApplied, setDocumentApplied] = useState<DocumentInterface>();

  const pendingTotal = useMemo(() => {
    return (
      document?.payments
        .filter((p) => p.status === PaymentStatusEnum.PENDING)
        .reduce((acc, payment) => acc + payment.amount, 0) ?? 0
    );
  }, [document?.payments]);

  const amountReceivable = useMemo(() => {
    if (!document) {
      return 0;
    }
    return (
      document.payments
        .filter(
          (payment) =>
            payment.status === PaymentStatusEnum.APPROVE ||
            payment.status === PaymentStatusEnum.PENDING
        )
        .reduce((total, payment) => {
          return payment.amount * 100 + total;
        }, 0) / 100
    );
  }, [document]);

  // Login
  // Login
  useEffect(() => {
    const Login = async () => {
      loaderService.start();
      const userLogged = await userLogin(
        process.env.REACT_APP_SHIPMENT_APP_USER!,
        process.env.REACT_APP_SHIPMENT_APP_PASS!,
        "120"
      );
      const user = userLogged as UserInterface;
      dispatch(setUser(user));
      const token = user?.token;
      localStorage.setItem("Token", token);

      const session = await CreateOrUpdateSession(user.userID, "120", "NN");
      if (session.didError) {
        console.log(session.errorMessage);
      }
      loaderService.stop();
    };

    Login();
  }, [dispatch]);

  useEffect(() => {
    if (!user.user) {
      return;
    }
    if (!documentID) {
      navigate(`/*/document-not-found`);
      return;
    }

    const getRouteDocument = async () => {
      setLoading(true);
      const document = await getDocument(documentID);
      setLoading(false);

      if (!document) {
        navigate(`/*/document-not-found`);
        return;
      }

      const accountOwner =
        (await getAccount(document.accountOwner?.id ?? "")) ??
        document.accountOwner;

      setDocument({
        ...document,
        accountOwner,
      });
    };

    getRouteDocument();
  }, [user.user, documentID, navigate]);

  useEffect(() => {
    if (!document?.documentAppliedID) return;

    const getDocumentApplied = async () => {
      const doc = await getDocument(document.documentAppliedID!);
      if (doc) {
        setDocumentApplied(doc);
      }
    };

    getDocumentApplied();
  }, [document?.documentAppliedID]);

  return (
    <div className="flex flex-col relative">
      {/* Back buttom */}
      <div onClick={() => navigate(-1)} className="absolute top-0 left-0 mt-4 ml-4 cursor-pointer flex items-center">
        <ChevronLeftIcon
          className="h-6 w-6 text-gray-700"
        />
        <span className="text-gray-700 font-semibold">Volver</span>
      </div>

      <div className="flex flex-1 items-center justify-center">
        <img src={logo} alt="Logo TEALCA" className={classNames("h-32 mt-8", hideLogo !== null && "hidden")} />
      </div>

      <div className="p-4 pb-8 shadow-lg rounded-lg mx-2 md:mx-5 mb-8 mt-8 flex flex-col lg:flex-row gap-10 border border-gray-300 bg-white">
        <div
          className={classNames(
            "flex items-center justify-center flex-1",
            !loading && "hidden"
          )}
        >
          <div className="flex flex-col items-center justify-center flex-1 p-8 rounded-2xl my-20 min-w-20 min-h-20 max-w-64 max-h-64">
            <LoadingIcon size="5rem" />
            <p className="mt-2 text-xl text-center font-light text-gray-900">
              Cargando factura...
            </p>
          </div>
        </div>

        <div
          className={classNames(
            "flex flex-1 flex-col gap-2",
            loading && "hidden"
          )}
        >
          {document && (
            <>
              <div className="flex flex-1 flex-row justify-between items-center bg-white rounded-lg border p-4 sm:px-8 sm:py-4">
                <div>
                  <div className="flex flex-col xl:flex-row xl:gap-2">
                    <p className="font-bold text-xl">
                      {document.documentType === DocumentType.INVOICE
                        ? "Factura:"
                        : document.documentType === DocumentType.CREDIT_NOTE
                        ? "Nota de Crédito:"
                        : "Orden:"}
                    </p>
                    <p className="text-indigo-600 font-bold text-xl">
                      {document.documentNumber ??
                        document.documentID.slice(0, 8)}
                    </p>
                  </div>

                  <LinkText
                    text={`Factura afectada ${document.documentAffectedDocumentNumber}`}
                    className={classNames(
                      (!document.documentAffectedID ||
                        document.documentType !== DocumentType.CREDIT_NOTE) &&
                        "hidden"
                    )}
                    onClick={() =>
                      navigate(`/document/${document.documentAffectedID}`)
                    }
                  />

                  <br
                    className={classNames(
                      (!document.documentAffectedID ||
                        document.documentType !== DocumentType.CREDIT_NOTE) &&
                        "hidden"
                    )}
                  />

                  <LinkText
                    text={
                      document.documentType === DocumentType.CREDIT_NOTE
                        ? `Utilizada como pago de la Factura ${document.documentAppliedDocumentNumber}`
                        : "Ver Nota de Crédito aplicada"
                    }
                    className={classNames(
                      !document.documentAppliedID && "hidden"
                    )}
                    onClick={() =>
                      navigate(`/document/${document.documentAppliedID}`)
                    }
                  />

                  <div className="flex flex-col md:flex-row md:gap-2 text-sm">
                    <p className="text-gray-600">Creación:</p>
                    <p className="text-gray-800 font-semibold">
                      {!!document?.creationDate &&
                        new Date(document.creationDate)
                          .toLocaleDateString("es-VE", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                          })
                          .replace("a", "AM")
                          .replace("p", "PM")
                          .slice(0, -4)}
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-5 flex flex-1 flex-col gap-6">
                {/* Show invoice owner */}
                {!!document.accountOwner && (
                  <div className="flex flex-1 gap-8 flex-col-reverse sm:flex-row items-start justify-between bg-white rounded-lg border p-4 sm:px-8 sm:py-4">
                    <div className="flex flex-1 flex-col">
                      <div className="flex flex-1 flex-col">
                        <p className="text-sm text-gray-900 font-semibold">
                          FACTURA A NOMBRE DE:
                        </p>
                        <p className="text-sm truncate">
                          {document.accountOwner.accountFullName}
                        </p>
                      </div>

                      <div className="flex flex-1 flex-col sm:mt-4">
                        <ClientDetails client={document.accountOwner} />
                      </div>
                    </div>

                    <div className="flex flex-col sm:items-end">
                      <div className="sm:text-end">
                        <p>Estado:</p>

                        <p className="font-medium text-xl leading-none italic">
                          {document.documentAppliedID &&
                          document.documentType !== DocumentType.CREDIT_NOTE &&
                          documentApplied?.total === document.total
                            ? "Anulada"
                            : documentStatusFormat(document.status)}
                        </p>
                      </div>

                      {!!document.urlDocument && (
                        <LinkText
                          text={
                            document.documentType === DocumentType.INVOICE
                              ? "Ver Factura"
                              : "Ver Nota de Crédito"
                          }
                          onClick={() =>
                            window.open(document.urlDocument!, "_blank")
                          }
                        />
                      )}

                      {!document.urlDocument &&
                        document.documentType === DocumentType.INVOICE && (
                          <p className="text-xs text-gray-400 text-right mt-1">
                            *Factura fiscal digital no disponible.
                          </p>
                        )}

                      {!document.urlDocument &&
                        document.documentType === DocumentType.ORDER && (
                          <p className="text-xs text-gray-400 text-right mt-1">
                            *Esta orden no ha generado factura fiscal.
                          </p>
                        )}
                    </div>
                  </div>
                )}

                {/* Show shipments resumen */}
                <div className={"pr-1"} style={{ flex: 1 }}>
                  <ShipmentTable
                    shipments={document.shipments}
                    showDetails={false}
                  />
                </div>

                {/* Show totals */}
                <PaymentTotal
                  baseRemaining={
                    (document.baseBalanceAmount ?? 0) - pendingTotal
                  }
                  paymentMode={document.paymentMode}
                  subTotal={document.subTotal}
                  total={document.total}
                  taxes={document.taxes}
                />

                {/* Show pay methods */}
                <ShipmentPayments document={document} />

                <div className="flex flex-1 justify-end">
                  {/* Pay Button */}
                  {amountReceivable < document.total &&
                    document.status !== DocumentStatus.ANULLED && (
                      <PrimaryButton
                        onClick={() =>
                          navigate(`/shipment/${document.shipments[0].id}/pay`)
                        }
                        className={classNames(
                          "w-full sm:w-40 mt-4 sm:mt-0",
                          !!document.documentAppliedID && "hidden"
                        )}
                      >
                        <CurrencyDollarIcon
                          className="h-5 w-5 flex-none text-white-400 mr-2"
                          aria-hidden="true"
                        />
                        PAGAR
                      </PrimaryButton>
                    )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Document;
