import { FC, useEffect, useMemo, useState } from "react";
import { validate as isValidUUID } from "uuid";
import { useParams } from "react-router-dom";
import logo from "../assets/LogoTEALCA.svg";
import classNames from "classnames";
import PieceTable from "../components/PieceTable";
import LoadingIcon from "../components/LoadingIcon";
import { useNavigate } from "react-router-dom";
import ShipmentItemTable from "../components/ShipmentItemTable";
import { LinkText, PrimaryButton } from "../components/Buttons";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { StoreShipmentInterface } from "../interfaces/Dtos";
import {
  CreateOrUpdateSession,
  alertService,
  getDocument,
  getShipment,
  getShipmentRates,
  getShipmentTracking,
  loaderService,
  userLogin,
} from "../services";
import {
  DocumentStatus,
  ShipmentInterface,
  DocumentResumeInterface,
  PaymentMode,
  ShipmentTracking,
  ShipmentService,
  AccountInterface,
  BusinessUnitInterface,
  LocationInterface,
  DocumentInterface,
  UserInterface,
  PaymentStatusEnum,
} from "../interfaces";
import {
  formatDate,
  paymentModeFormat,
  documentStatusFormat,
  currencyExchangeText,
  useCurrencyExchanges,
} from "../utils";
import { setUser } from "../store/slices";

export interface ShipmentViewProps {
  shipment?: ShipmentInterface;
  shipments?: StoreShipmentInterface[];
  shipmentDocument?: DocumentResumeInterface;
  hideButtons?: boolean;
}
const ShipmentView: FC<ShipmentViewProps> = ({ shipment, hideButtons }) => {
  const { shipmentNumber } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const exchanges = useCurrencyExchanges();
  const businessUnits = useAppSelector(
    (state) => state.inmutable.businessUnits
  );
  const applicationID = useAppSelector(
    (state) => state.inmutable.appData.applicationID
  );

  const url = new URL(window.location.href);
  const hideLogo = url.searchParams.get("hide-logo");

  const [logged, setLogged] = useState(false);
  const [shipment_, setShipment] = useState(shipment);
  const [document_, setDocument] = useState<DocumentInterface>();
  const [tracking, setTracking] = useState<ShipmentTracking[]>([]);

  const ipostel = useMemo(() => {
    return shipment_?.totalPostalTaxBaseCurr === 0
      ? shipment_?.items.reduce((acc, item) => acc + item.rate.ipostel, 0)
      : shipment_?.totalPostalTaxBaseCurr;
  }, [shipment_]);

  const totalWithTaxes = useMemo(() => {
    return (
      (shipment_?.total ?? 0) +
      (shipment_?.items.reduce((acc, item) => acc + item.rate.iva, 0) ?? 0) +
      (ipostel ?? 0)
    );
  }, [shipment_, ipostel]);

  const isPaid = useMemo(() => {
    return (
      !!document_ &&
      (document_.status === DocumentStatus.PAID ||
        document_.balanceAmount -
          document_.payments
            .filter((p) => p.status === PaymentStatusEnum.PENDING)
            .reduce((acc, p) => acc + p.amount, 0) <=
          0.01)
    );
  }, [document_]);

  const handleDocumentView = async () => {
    if (!document_?.documentID) return;

    navigate(`/document/${document_.documentID}`);
  };

  const updateModalShipment = async () => {
    if (!shipmentNumber || !!shipment || !isValidUUID(shipmentNumber)) {
      navigate(`/*/shipment-not-found`);
      return;
    }

    getShipment(shipmentNumber).then((shipment) => {
      if (!shipment) {
        navigate(`/*/shipment-not-found`);
        return;
      }

      if (!shipment.document) {
        getShipmentRates(
          shipment.service ?? ShipmentService.STANDARD,
          shipment.paymentMode ?? PaymentMode.COD,
          shipment.deliveryType,
          shipment.buSource.code,
          businessUnits.find((bu) => bu.code === shipment.buConsignee?.code!)!
            .code ?? "",
          shipment.shipper.id,
          shipment.consignee.id,
          shipment.accountBillTo?.id,
          businessUnits.find((bu) => bu.code === shipment.buSource?.code!)!
            .location,
          shipment.consigneeAddress,
          shipment.items,
          shipment.pieces,
          shipment.pieces.reduce((acc, p) => acc + p.value, 0) > 0,
          new Date().toISOString(),
          applicationID
        ).then((response) => {
          const rates = response.model?.items;
          if (!!rates) {
            setShipment({
              ...shipment,
              items: rates,
              total: rates.reduce((acc, i) => acc + i.rate.value, 0),
            });
          } else {
            alertService.warn(
              `Hubo un error al obtener la tarifa de la guía ${shipment.shipmentNumber}.`
            );
            setShipment(shipment);
          }
        });
      } else {
        setShipment(shipment);
      }
    });
  };

  // Login
  useEffect(() => {
    const Login = async () => {
      loaderService.start();
      const userLogged = await userLogin(
        process.env.REACT_APP_SHIPMENT_APP_USER!,
        process.env.REACT_APP_SHIPMENT_APP_PASS!,
        "120"
      );
      const user = userLogged as UserInterface;
      dispatch(setUser(user));
      setLogged(true);
      const token = user?.token;
      localStorage.setItem("Token", token);

      const session = await CreateOrUpdateSession(user.userID, "120", "NN");
      if (session.didError) {
        console.log(session.errorMessage);
      }
      loaderService.stop();
    };

    Login();
  }, [dispatch]);

  useEffect(() => {
    if (!shipment) {
      setShipment(undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user.user && logged) {
      updateModalShipment();
    }
  }, [shipmentNumber, shipment, businessUnits, user.user]);

  useEffect(() => {
    setShipment(shipment);
  }, [shipment]);

  useEffect(() => {
    const documentID = shipment_?.document?.documentId;
    if (!documentID) return;

    const fetchDocument = async () => {
      const document = await getDocument(documentID);
      if (!document) return;

      setDocument(document);
    };

    fetchDocument();
  }, [shipment_]);

  useEffect(() => {
    const service = shipment_?.service;
    const buSource = shipment_?.buSource?.code;
    const shipmentNumber = shipment_?.shipmentNumber;

    if (!shipmentNumber || !service || !buSource) return;

    const getTracking = async () => {
      const tracking = await getShipmentTracking(
        shipmentNumber,
        service,
        buSource
      );
      if (!tracking) return;

      setTracking(
        tracking.sort((a, b) => {
          return new Date(a.date).getTime() - new Date(b.date).getTime();
        })
      );
    };

    getTracking();
  }, [shipment_]);

  return (
    <>
      <div className="flex flex-1 items-center justify-center">
        <img src={logo} alt="Logo TEALCA" className={classNames("h-32 mt-8", hideLogo !== null && "hidden")} />
      </div>
      <div
        className={classNames(
          "overflow-hidden rounded-2xl shadow-lg bg-white mx-5 p-6 flex flex-1 flex-col mb-8 mt-8",
          !shipment_ && "hidden"
        )}
      >
        {/* Header */}
        <div className="flex flex-row items-center justify-between bg-white rounded-lg">
          <div>
            <div className="flex flex-row gap-2">
              <p className="font-bold text-xl">Guía:</p>
              <p className="text-indigo-600 font-bold text-xl">
                {shipment_?.shipmentNumber}
              </p>
            </div>

            <div className="flex flex-row gap-2 text-sm">
              <p className="text-gray-600">Creación:</p>
              <p className="text-gray-800 font-bold">
                {!!shipment_?.createdAt &&
                  new Date(shipment_.createdAt)
                    .toLocaleDateString("es-VE", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                    .replace("a", "AM")
                    .replace("p", "PM")
                    .slice(0, -4)}
              </p>
            </div>
          </div>
        </div>

        <div
          className={classNames(
            "flex flex-row items-center text-lg gap-2 -mt-2 font-semibold",
            !shipment_?.substituteFor && "hidden"
          )}
        >
          <p>Sustituto de:</p>
          <LinkText
            text={shipment_?.substituteFor ?? ""}
            onClick={() => {
              navigate(`/shipments/${shipment_?.substituteFor}`);
            }}
          />
        </div>

        <div
          className={classNames(
            "flex flex-row items-center text-lg gap-2 -mt-2 font-semibold",
            !shipment_?.substitutedBy && "hidden"
          )}
        >
          <p>Sustituido por:</p>
          <LinkText
            text={shipment_?.substitutedBy ?? ""}
            onClick={() => {
              navigate(`/shipments/${shipment_?.substitutedBy}`);
            }}
          />
        </div>

        <hr className="my-2" />

        {/* Main data */}
        <div className="flex flex-1 flex-col md:flex-row  gap-4">
          <div className="flex flex-1 flex-col lg:flex-row">
            {/* Shipper */}
            <ShipmentDetailsClientData
              isShipper
              client={shipment_?.shipper}
              businessUnit={shipment_?.buSource}
            />

            {/* Consignee */}
            <ShipmentDetailsClientData
              client={shipment_?.consignee}
              businessUnit={shipment_?.buConsignee}
              location={shipment_?.consigneeAddress}
              showLocation={shipment_?.deliveryType === 20}
            />
          </div>

          <div>
            {/* Resume */}
            <div className="flex flex-col md:items-end items-start  text-gray-800 leading-tight pb-4">
              <p className="font-medium text-lg leading-none">
                {paymentModeFormat(shipment_?.paymentMode).toUpperCase()}
              </p>
              <p
                className={classNames(
                  "leading-none mb-2",
                  shipment_?.paymentMode !== PaymentMode.CREDIT && "hidden"
                )}
              >
                {shipment_?.accountBillTo?.accountFullName}
              </p>
              <p className="font-semibold">
                <span className="font-normal">Con impuestos: </span>
                {currencyExchangeText(totalWithTaxes, exchanges, "USD")}
              </p>
              <p className="">
                Nº de piezas:{" "}
                <span className="font-semibold">
                  {shipment_?.pieces.length}
                </span>
              </p>
              <p className="">
                {!!document_?.urlDocument
                  ? "Peso facturado: "
                  : "Peso por facturar: "}
                <span className="font-semibold">
                  {shipment_?.totalChargedWeight?.toFixed(2)} Kg
                </span>
              </p>
              <p className="text-xs mt-2">
                Peso balanza:{" "}
                <span className="font-semibold">
                  {shipment_?.totalPhysicalWeight?.toFixed(2)} Kg
                </span>
              </p>
              <p className="text-xs">
                Peso volumétrico:{" "}
                <span className="font-semibold">
                  {shipment_?.totalDimensionalWeight?.toFixed(2)} Kg
                </span>
              </p>
            </div>
          </div>
        </div>
        <hr className="my-2" />

        {/* Details */}
        <div className="flex flex-1 flex-col gap-4">
          <div className="flex items-center w-full gap-4">
            <p className="font-light text-2xl">Detalles</p>
          </div>

          <div
            className={classNames(
              "flex flex-col",
              (!document_ ||
                [3, 4].includes(
                  shipment_?.shipper.taxIdentificationTypeID ?? 1
                )) &&
                "hidden"
            )}
          >
            <p className="font-medium text-gray-800 text-lg"> Factura </p>

            <div className="w-full overflow-x-auto">
              <table className="table-auto w-full mt-1">
                <thead>
                  <tr>
                    <th className="text-left px-4 py-2 font-semibold text-xs">
                      ID FACTURA
                    </th>

                    <th className="text-left px-4 py-2 font-semibold text-xs">
                      FECHA DE FACTURA
                    </th>

                    <th className="text-left px-4 py-2 font-semibold text-xs truncate">
                      A NOMBRE DE
                    </th>

                    <th className="text-left px-4 py-2 font-semibold text-xs truncate">
                      TOTAL FACTURA
                    </th>
                    <th className="text-left px-4 py-2 font-semibold text-xs truncate">
                      MONTO POR COBRAR
                    </th>

                    <th className="text-left px-4 py-2 font-semibold text-xs truncate">
                      MONTO A REINTEGRAR
                    </th>

                    <th className="text-right px-4 py-2 font-semibold text-xs">
                      ESTADO
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    className={"cursor-pointer hover:bg-gray-100 "}
                    onClick={handleDocumentView}
                  >
                    <td
                      className="px-4 py-4 text-xs text-gray-500 font-semibold truncate"
                      style={{ maxWidth: "10rem" }}
                    >
                      {document_?.documentNumber}
                    </td>

                    <td className="px-4 py-4 text-xs text-gray-500 font-semibold">
                      {!!document_?.creationDate &&
                        formatDate(document_.creationDate?.toISOString())}
                    </td>

                    <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
                      {document_?.accountOwner?.accountFullName}
                    </td>

                    <td className="px-4 py-4 text-xs text-gray-500 font-semibold">
                      <span className="font-medium">
                        {currencyExchangeText(
                          document_?.total ?? 0,
                          exchanges,
                          "USD"
                        )}
                      </span>
                    </td>

                    <td className="px-4 py-4 text-xs text-gray-500 font-semibold">
                      <span className="font-medium">
                        {currencyExchangeText(
                          Math.max(0, document_?.balanceAmount ?? 0),
                          exchanges,
                          "USD"
                        )}
                      </span>
                    </td>

                    <td className="px-4 py-4 text-xs text-gray-500 font-semibold">
                      <span className="font-medium">
                        {currencyExchangeText(
                          (document_?.balanceAmount ?? 0) < 0
                            ? -1 * document_?.balanceAmount!
                            : 0,
                          exchanges,
                          "USD"
                        )}
                      </span>
                    </td>

                    <td className="px-4 py-4 text-xs text-right text-gray-500 font-semibold truncate">
                      {documentStatusFormat(
                        document_?.status ?? DocumentStatus.PENDING
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <hr className="mb-4 mt-6" />
          </div>

          <div className={classNames("flex flex-col", !shipment_ && "hidden")}>
            <p className="font-medium text-gray-800 text-lg"> Piezas </p>
            <div className="w-full overflow-x-auto">
              <PieceTable pieces={shipment_?.pieces ?? []} />
            </div>
          </div>

          <hr className="my-4" />

          <div className={classNames("flex flex-col", !shipment_ && "hidden")}>
            <p className="font-medium text-gray-800 text-lg mb-2"> Items </p>
            <ShipmentItemTable
              showTaxes
              total={shipment_?.total ?? 0}
              items={shipment_?.items ?? []}
              distance={shipment_?.deliveryDistance}
              declaredValue={+(shipment_?.declaredValue ?? "0")}
            />

            <div className="flex flex-col md:flex-row-reverse md:items-center gap-6 mt-3 mb-2">
              <div className="flex-1">
                <p className="text-right text-sm text-gray-700">
                  Tasa de cambio BCV:{" "}
                  {currencyExchangeText(1, exchanges, "BS", "USD")}
                </p>
                <p className="w-full italic text-right text-xs text-gray-500">
                  Los montos en bolívares se calculan a la tasa de <br />
                  cambio del Banco Central de Venezuela (BCV) <br />
                  vigente al día.
                </p>
              </div>

              <div
                className={classNames(
                  "flex flex-1 justify-center",
                  isPaid && "hidden",
                  // "hidden"
                )}
              >
                <PrimaryButton
                  onClick={() => navigate(`/shipment/${shipment_?.id}/pay`)}
                  className="w-full sm:w-40 mt-4 sm:mt-0"
                >
                  Pagar
                </PrimaryButton>
              </div>

              <div className="flex-1 hidden md:block" />
            </div>
          </div>

          <hr
            className={classNames(
              "mt-4 mb-10",
              tracking.length === 0 && "hidden"
            )}
          />

          <div
            className={classNames(
              "flex flex-col",
              tracking.length === 0 && "hidden"
            )}
          >
            <p className="font-medium text-gray-800 text-xl mb-4"> Tracking </p>

            <table className="table-auto w-full mb-8">
              <thead>
                <tr>
                  <th className="text-left px-4 py-2 font-semibold text-xs">
                    EVENTO
                  </th>
                  <th className="text-left px-4 py-2 font-semibold text-xs">
                    DESCRIPCIÓN
                  </th>
                </tr>
              </thead>
              <tbody>
                {tracking.map((t, i) => (
                  <tr key={i} className="bg-gray-50">
                    <td className="px-4 py-4 text-xs text-gray-500 font-semibold">
                      {t.status}
                      <br />
                      {t.date}
                    </td>
                    <td className="px-4 py-4 text-xs text-gray-500 font-semibold">
                      {t.description}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <hr className="my-2" />

        {/* Buttons */}
        <div
          className={classNames(
            "flex flex-1 justify-between mt-8",
            hideButtons && "hidden"
          )}
        ></div>
      </div>
      <div className="flex items-center justify-center">
        <div
          className={classNames(
            "flex flex-col items-center justify-center flex-1 p-8 rounded-2xl shadow-lg bg-white mt-20 min-w-20 min-h-20 max-w-64 max-h-64",
            shipment_ && "hidden"
          )}
        >
          <LoadingIcon size="5rem" />
          <p className="mt-2 text-xl text-center font-light text-gray-900">
            Cargando guía...
          </p>
        </div>
      </div>
    </>
  );
};

interface ShipmentDetailsClientDataProps {
  client?: AccountInterface;
  businessUnit?: BusinessUnitInterface;
  location?: LocationInterface;
  showLocation?: boolean;
  isShipper?: boolean;
}
const ShipmentDetailsClientData: FC<ShipmentDetailsClientDataProps> = ({
  client,
  businessUnit,
  location,
  showLocation,
  isShipper = false,
}) => {
  const countries = useAppSelector((state) => state.inmutable.countries);
  const taxIdentificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );

  const identificationType = useMemo(() => {
    return taxIdentificationTypes.find(
      (t) => t.taxIdentificationTypeId === client?.taxIdentificationTypeID
    );
  }, [client, taxIdentificationTypes]);

  const country = useMemo(() => {
    return countries.find(
      (c) => c.id === client?.listAccountPhone[0]?.countryID
    );
  }, [client, countries]);

  return (
    <div className="flex flex-1 flex-col pb-4">
      <p className="font-light text-xl">
        {isShipper ? "Remitente" : "Destinatario"}:
      </p>
      <p className="font-medium text-gray-800 ">{client?.accountFullName}</p>
      <p className="text-gray-800 font-light leading-none">
        {identificationType?.abreviationName}
        {client?.identificationNumber}
      </p>
      <p className="text-gray-800 font-light leading-none">
        {country?.countryPhoneAccessCode}{" "}
        {client?.listAccountPhone[0]?.phoneNumber}
      </p>
      <p className="text-gray-800">
        {businessUnit?.code} - {businessUnit?.location.name}
      </p>

      <p
        className={classNames(
          "text-gray-800 text-sm",
          !showLocation && "hidden"
        )}
      >
        {`${location?.name} - ${location?.address} (${location?.postalCode})`}
      </p>
      <p
        className={classNames(
          "text-gray-800 text-sm",
          (!location?.reference || !showLocation) && "hidden"
        )}
      >
        Punto de ref: {location?.reference}
      </p>
    </div>
  );
};

export default ShipmentView;
