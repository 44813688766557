import { FC, useState } from "react";
import {
  FormDatePicker,
  FormSelect,
  FormTextInput,
} from "../../components/FormFields";
import { Formik } from "formik";
import * as Yup from "yup";
import { formatFloat } from "../../utils";
import { LinkText, PrimaryButton } from "../../components/Buttons";
import classNames from "classnames";
import moment from "moment";
import Modal from "../../components/Modal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import logo from "../../assets/LogoTEALCA.svg";
import {
  PaymentInterface,
  PaymentMethodEnum,
  PaymentStatusEnum,
} from "../../interfaces";

const IDENTIFICATION_TYPES = ["V", "E", "P", "J", "G"];

export enum PaymentDateEnum {
  LAST_4_DAYS = "Últimos 3 días",
  LAST_8_DAYS = "Últimos 7 días",
  SPECIFIC_DATE = "Fecha específica",
}

interface P2CPaymentFormProps {
  remaining: number;
  onPay: (
    payment: PaymentInterface,
    avoidVerifications?: boolean
  ) => Promise<{
    error: boolean;
    message: string;
  }>;
}

const P2CPaymentForm: FC<P2CPaymentFormProps> = ({ onPay, remaining }) => {
  const [p2cModal, setP2cModal] = useState(false);

  const validationSchema = Yup.object().shape({
    identificationType: Yup.string().required("Este campo es requerido"),
    clientIdentifier: Yup.string().required("Este campo es requerido"),
    phoneNumber: Yup.string().required("Este campo es requerido"),
    reference: Yup.string().required("Este campo es requerido"),
    amount: Yup.number()
      .required("Este campo es requerido")
      .max(remaining, "El monto no puede ser mayor al saldo restante"),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        identificationType: "V",
        clientIdentifier: "",
        phoneNumber: "",
        amount: remaining > 0.01 ? remaining.toFixed(2) : "0",
        reference: "",
        dateType: PaymentDateEnum.LAST_4_DAYS,
        date: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        const payment: PaymentInterface = {
          status: PaymentStatusEnum.APPROVE,
          isRetention: false,
          amount: +values.amount,
          paymentAmount: +values.amount,
          clientIdentifier: values.identificationType + values.clientIdentifier,
          paymentMethod: {
            paymentMethodID: PaymentMethodEnum.PAGO_MOVIL_P2C,
            paymentMethodName: "Pago Móvil P2C",
            currencyID: 1,
          },
          destBankID: 4,
          phone: values.phoneNumber,
          reference: values.reference,
          igtfAmount: 0,
          igtfPercentage: 0,
          paymentDate:
            values.dateType === PaymentDateEnum.SPECIFIC_DATE
              ? values.date
              : new Date().toISOString(),
          attempts:
            values.dateType === PaymentDateEnum.LAST_4_DAYS
              ? 4
              : values.dateType === PaymentDateEnum.LAST_8_DAYS
              ? 8
              : 1,
        };
        const response = await onPay(payment, true);
        if (!response.error) {
          resetForm();
        }
      }}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <div className="flex gap-4 flex-col sm:flex-row">
            <div className="flex flex-1">
              <div className="flex flex-col">
                <div>
                  <FormSelect
                    containerClassName="rounded rounded-r-none"
                    name="identificationType"
                    type="text"
                    label="Tipo"
                    options={IDENTIFICATION_TYPES}
                    optionString={(identificationTypes: string) =>
                      identificationTypes
                    }
                    selected={formik.values.identificationType}
                    onSelectOption={(value) => {
                      formik.setFieldValue("identificationType", value);
                    }}
                  />
                </div>
              </div>

              <div className="flex w-full flex-col">
                <div>
                  <FormTextInput
                    className="rounded rounded-l-none"
                    name="clientIdentifier"
                    type="text"
                    label="Cédula del cliente"
                    placeholder="12345678"
                    onChange={formik.handleChange}
                    value={formik.values.clientIdentifier}
                    error={
                      formik.touched.clientIdentifier &&
                      formik.errors.clientIdentifier
                        ? formik.errors.clientIdentifier
                        : ""
                    }
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-1 flex-col">
              <div>
                <FormTextInput
                  name="phoneNumber"
                  type="text"
                  label="Número de teléfono"
                  placeholder="04121234567"
                  onChange={formik.handleChange}
                  value={formik.values.phoneNumber}
                  error={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                      ? formik.errors.phoneNumber
                      : ""
                  }
                />
              </div>
            </div>
          </div>

          <div className="flex gap-4 flex-col sm:flex-row">
            <div className="flex flex-1 flex-col">
              <div>
                <FormTextInput
                  name="amount"
                  type="text"
                  label="Monto"
                  placeholder="0.00"
                  onChange={(e) => {
                    formatFloat(e.target.value, (v) =>
                      formik.setFieldValue("amount", v)
                    );
                  }}
                  value={formik.values.amount}
                  error={
                    formik.touched.amount && formik.errors.amount
                      ? formik.errors.amount
                      : ""
                  }
                />
              </div>
            </div>

            <div className="flex flex-1 flex-col justify-start">
              <div>
                <FormTextInput
                  name="reference"
                  type="text"
                  label="Referencia"
                  placeholder="Ingrese los últimos 4 dígitos"
                  onChange={formik.handleChange}
                  value={formik.values.reference}
                  error={
                    formik.touched.reference && formik.errors.reference
                      ? formik.errors.reference
                      : ""
                  }
                />
              </div>
            </div>
          </div>

          <div className="flex gap-4 flex-col sm:flex-row">
            <div className="flex flex-1 flex-col">
              <FormSelect
                name="dateType"
                type="text"
                label="Fecha"
                options={[
                  PaymentDateEnum.LAST_4_DAYS,
                  PaymentDateEnum.LAST_8_DAYS,
                  PaymentDateEnum.SPECIFIC_DATE,
                ]}
                optionString={(type) => type}
                selected={formik.values.dateType}
                onSelectOption={(value) => {
                  formik.setFieldValue("dateType", value);
                }}
              />
            </div>

            <div
              className={classNames(
                "flex flex-col flex-1 justify-end",
                formik.values.dateType !== PaymentDateEnum.SPECIFIC_DATE &&
                  "hidden"
              )}
            >
              <FormDatePicker
                id="date"
                name="date"
                label=""
                useRange={false}
                maxDate={new Date()}
                minDate={moment().subtract(30, "days").toDate()}
                placeholder="Elegir fecha"
                error={
                  formik.touched.date && formik.errors.date
                    ? formik.errors.date
                    : undefined
                }
                value={{
                  startDate: formik.values.date
                    ? moment(formik.values.date).toDate()
                    : null,
                  endDate: formik.values.date
                    ? moment(formik.values.date).toDate()
                    : null,
                }}
                toggleClassName={(oldClassname) =>
                  classNames(oldClassname, "text-indigo-600")
                }
                onChange={(e) => {
                  formik.setFieldValue(
                    "date",
                    !!e?.startDate ? moment(e.startDate).toISOString() : null
                  );
                }}
                configs={{
                  shortcuts: {},
                }}
              />
            </div>

            <div className="flex items-end">
              <PrimaryButton className="w-full mt-6 sm:w-32 sm:mt-0">
                Reportar
              </PrimaryButton>
            </div>
          </div>

          <div className="flex justify-between flex-col sm:flex-row">
            <div className="flex-1">
              <LinkText
                onClick={() => setP2cModal(true)}
                text="Datos del Pago Móvil"
                className="text-sm truncate"
              />
            </div>

            <div className="text-center sm:text-right text-xs text-gray-500 mt-4 sm:mt-0">
              <p className="text-balance">
                Se debe suministrar por lo menos los últimos cuatro (4) dígitos
                de la referencia. Es posible verificar operaciones con una
                antigüedad máxima de treinta (30) días.
              </p>
            </div>
          </div>

          <Modal
            openModal={p2cModal}
            setOpenModal={setP2cModal}
            className="relative max-w-lg w-full"
          >
            <button
              type="button"
              className="absolute top-4 right-4 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              onClick={() => setP2cModal(false)}
            >
              <span className="absolute -inset-2.5" />
              <span className="sr-only">Close panel</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>

            <div className="bg-white pl-4 pb-4">
              <div className="flex items-center">
                <div className="flex-shrink-0 flex items-center justify-center h-24 w-24 rounded-full bg-blue-100">
                  <img src={logo} className="App-logo" alt="Logo" />
                </div>
                <div className="text-left ml-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Datos pago móvil
                  </h3>
                  <div className="mt-2">
                    <p className="text-md text-gray-500">
                      Banco: <span className="font-bold">Mercantil (0105)</span>
                    </p>
                    <p className="text-md text-gray-500">
                      RIF: <span className="font-bold">J-002206080</span>
                    </p>
                    <p className="text-md text-gray-500">
                      Teléfono: <span className="font-bold">0424-1668634</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </form>
      )}
    </Formik>
  );
};

export default P2CPaymentForm;
