import React, { FC, useState } from "react";
import { FormSelect } from "../../components/FormFields";
import { PaymentInterface, PaymentMethodEnum } from "../../interfaces";
import P2CPaymentForm from "./P2CPaymentForm";

const PAYMENT_METHODS = [
  {
    paymentMethodID: PaymentMethodEnum.PAGO_MOVIL_P2C,
    paymentMethodName: "Pago Móvil P2C",
    currencyID: 1,
  },
];

interface PaymentFormProps {
  remaining: number;
  onPay: (
    payment: PaymentInterface,
    avoidVerifications?: boolean
  ) => Promise<{
    error: boolean;
    message: string;
  }>;
}

const PaymentForm: FC<PaymentFormProps> = ({ onPay, remaining }) => {
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHODS[0]);

  return (
    <div>
      <h2 className="text-lg font-semibold text-gray-700 mb-4">
        Reportar pago
      </h2>

      <div className="flex flex-col flex-1 mb-4">
        <FormSelect
          label="Método de pago"
          name="paymentMethod"
          options={PAYMENT_METHODS}
          optionString={(p) => p.paymentMethodName}
          selected={paymentMethod}
          onSelectOption={setPaymentMethod}
        />
      </div>

      <P2CPaymentForm onPay={onPay} remaining={remaining} />
    </div>
  );
};

export default PaymentForm;
