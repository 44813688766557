import React, { FC, useMemo } from "react";
import { PaymentMode, TaxInterface } from "../../interfaces";
import {
  currencyExchangeText,
  useCurrencyExchanges,
} from "../../utils";
import classNames from "classnames";

export interface PaymentTotalProps {
  showEstimate?: boolean;
  paymentMode?: PaymentMode;
  subTotal: number;
  total?: number;
  remaining?: number;
  baseRemaining?: number;
  taxes: TaxInterface[];
  allowPaymentModeChange?: boolean;
  className?: string;
  onChangePaymentMode?: () => void;
}

const PaymentTotal: FC<PaymentTotalProps> = ({
  subTotal,
  total,
  taxes,
  remaining,
  baseRemaining,
  allowPaymentModeChange,
  className,
}) => {
  const exchanges = useCurrencyExchanges();

  const realTotal = useMemo(() => {
    if (total !== undefined) return total;

    const totalTaxes = taxes.reduce((acc, tax) => {
      if (tax.type === "percentage") {
        return acc + subTotal * (tax.value / 100);
      }
      return acc + tax.value;
    }, 0);

    return subTotal + totalTaxes;
  }, [subTotal, taxes, total]);

  const realTaxes = useMemo(() => {
    // Join and sum taxes with same value
    const taxesWithSameValue: TaxInterface[] = [];
    taxes.forEach((tax) => {
      const index = taxesWithSameValue.findIndex(
        (taxWithSameValue) => taxWithSameValue.name === tax.name
      );
      if (index === -1) {
        taxesWithSameValue.push({ ...tax });
      } else {
        taxesWithSameValue[index].value += tax.value;
      }
    });

    return taxesWithSameValue;
  }, [taxes]);

  const realRemaining = useMemo(() => {
    if (baseRemaining !== undefined) {
      return {
        usd: currencyExchangeText(
          Math.max(0, baseRemaining),
          exchanges,
          "USD",
          "BS"
        ),
        bs: currencyExchangeText(
          Math.max(0, baseRemaining),
          exchanges,
          "BS",
          "BS"
        ),
      };
    } else if (remaining !== undefined) {
      return {
        usd: currencyExchangeText(
          Math.max(0, remaining),
          exchanges,
          "USD",
          "BS"
        ),
        bs: currencyExchangeText(Math.max(0, remaining), exchanges, "BS", "BS"),
      };
    }
    return undefined;
  }, [remaining, baseRemaining, exchanges]);

  return (
    <div
      className={classNames(
        "flex flex-col sm:flex-row bg-white rounded-lg border gap-6 sm:gap-4 p-4 sm:px-8 sm:pb-6 sm:pt-4",
        className
      )}
    >
      <div className="flex flex-1 flex-col">
        <div className="flex flex-row justify-between">
          <p className="text-gray-400">Sub total</p>
          <div className="flex flex-col items-end">
            <p className="font-semibold text-gray-600">
              {currencyExchangeText(subTotal, exchanges, "USD")}
            </p>
            <p className="text-xs text-gray-400 ">
              {currencyExchangeText(subTotal, exchanges, "BS")}
            </p>
          </div>
        </div>
        <hr className="my-2" />

        {realTaxes.map((tax, i) => {
          const value =
            tax.type === "percentage"
              ? subTotal * (tax.value / 100)
              : tax.value;

          return (
            <React.Fragment key={i}>
              <div className="flex flex-row justify-between">
                <p className="text-gray-400">{tax.name}</p>
                <div className="flex flex-col items-end">
                  <p className="font-semibold text-gray-600">
                    {currencyExchangeText(value, exchanges, "USD")}
                  </p>
                  <p className="text-xs text-gray-400 ">
                    {currencyExchangeText(value, exchanges, "BS")}
                  </p>
                </div>
              </div>
              <hr className="my-2" />
            </React.Fragment>
          );
        })}

        <div className="flex flex-row justify-between">
          <p className="text-gray-400">Total de factura</p>
          <div className="flex flex-col items-end">
            <p className="font-semibold text-gray-600">
              {currencyExchangeText(realTotal, exchanges, "USD")}
            </p>
            <p className="text-xs text-gray-400 ">
              {currencyExchangeText(realTotal, exchanges, "BS")}
            </p>
          </div>
        </div>

        {!!realRemaining ? (
          <div className="flex flex-col items-end justify-end mt-4 gap-4">
            <p className="text-sm text-gray-400">Total por pagar:</p>
            <div className="flex flex-col items-end">
              <p className="font-semibold text-2xl">{realRemaining.usd}</p>
              <p className="text-xs text-gray-400 ">{realRemaining.bs}</p>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-end justify-end mt-4 gap-4">
            <p className="text-sm text-gray-400">Total:</p>
            <div className="flex flex-col items-end">
              <p className="font-semibold text-2xl">
                {currencyExchangeText(realTotal, exchanges, "USD")}
              </p>
              <p className="text-xs text-gray-400 ">
                {currencyExchangeText(realTotal, exchanges, "BS")}
              </p>
            </div>
          </div>
        )}

        <div className="flex-1">
          <p className="text-right text-sm text-gray-700">
            Tasa de cambio BCV:{" "}
            {currencyExchangeText(1, exchanges, "BS", "USD")}
          </p>
          <p className="w-full italic text-right text-xs text-gray-500">
            Los montos en bolívares se calculan a la tasa de <br />
            cambio del Banco Central de Venezuela (BCV) <br />
            vigente al día.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentTotal;
