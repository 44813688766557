import React, { FC, useEffect, useState } from "react";
import logo from "../../assets/LogoTEALCA.svg";
import PriceCalculatorForm, {
  PIECES_INITIAL_VALUES,
  PriceCalculatorFormValues,
} from "./PriceCalculatorForm";
import PriceCalculatorResume from "./PriceCalculatorResume";
import { DeliveryType, PaymentMode, ShipmentService, UserInterface } from "../../interfaces";
import { CreateOrUpdateSession, loaderService, userLogin } from "../../services";
import classNames from "classnames";

const PriceCalculator: FC = () => {
  const [values, setValues] = useState<PriceCalculatorFormValues>({
    buSource: null,
    deliveryType: DeliveryType.AT_OFFICE,
    buConsignee: null,
    consigneeAddress: null,
    paymentMode: PaymentMode.CONTADO,
    service: ShipmentService.STANDARD,
    pieces: [],
    declaredValue: 0,
    totalChargedWeight: 0,
  });
  const [editMode, setEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [piecesInitialValues, setPiecesInitialValues] = useState(PIECES_INITIAL_VALUES);

  const url = new URL(window.location.href);
  const hideLogo = url.searchParams.get("hide-logo");

  // Login
  useEffect(() => {
    const Login = async () => {
      loaderService.start();
      const userLogged = await userLogin(
        process.env.REACT_APP_SHIPMENT_APP_USER!,
        process.env.REACT_APP_SHIPMENT_APP_PASS!,
        "120"
      );
      const user = userLogged as UserInterface;
      const token = user?.token;
      localStorage.setItem("Token", token);

      const session = await CreateOrUpdateSession(user.userID, "120", "NN");
      if (session.didError) {
        console.log(session.errorMessage);
      }
      loaderService.stop();
    };

    Login();
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex flex-1 items-center justify-center">
        <img src={logo} alt="Logo TEALCA" className={classNames("h-32 mt-8", hideLogo !== null && "hidden")} />
      </div>

      <div className="p-4 shadow-lg rounded-lg mx-2 md:mx-5 mb-8 mt-8 flex flex-col lg:flex-row gap-10 border border-gray-300 bg-white">
        <div className="flex flex-1 flex-col pb-10">
          <PriceCalculatorForm
            values={values}
            editMode={editMode}
            editIndex={editIndex}
            initialValues={piecesInitialValues}
            setValues={setValues}
            setEditMode={setEditMode}
            setInitialValues={setPiecesInitialValues}
          />
        </div>

        <div className="flex flex-1 flex-col">
          <PriceCalculatorResume
            values={values}
            editMode={editMode}
            editIndex={editIndex}
            setValues={setValues}
            setEditMode={setEditMode}
            setEditIndex={setEditIndex}
            setInitialValues={setPiecesInitialValues}
          />
        </div>
      </div>
    </div>
  );
};

export default PriceCalculator;
